/* Global CSS */

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: "Roboto", sans-serif;
  -moz-osx-font-smoothing: "Roboto", sans-serif;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

#skill-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
:root {
  --primary: #5e3bee;
  --heading-color: #282938;
  --bg-shade: #f5fcff;
  --github: #e62872;
  --darkblue: #1c1e53;
  --black: #000000;
  --white: #ffffff;
}

.btn {
  font-family: "Roboto";
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}

.btn-outline-primary {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}

.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary);
}

.btn-primary {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.btn-primary:hover {
  color: var(--primary);
  background-color: var(--white);
}

.btn-github {
  color: var(--white);
  background-color: var(--github);
  border: var(--github);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
  gap: 16px;
}

.btn-github:hover {
  color: var(--github);
  background-color: var(--white);
}

/* Global CSS ends here */

/*Main headings */

/*Heading 1*/
h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
}

/*Heading 2*/
h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}

/*
  NOTE: There's a duplicate "h1" below labeled as "Heading 3".
  Probably a mistake in the original code. Keeping as-is, but 
  you might want to rename it to "h3" if it's intended for heading 3.
*/

/*Heading 3*/
h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

/* paragraphs text, large medium and small */
/* body 1 */
.text-lg {
  color: var(--darkblue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

/* body 2 */
.text-md {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

/* body 3 */
.text-sm {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

/* section titles */
/* skills & About me */

.section-title {
  color: var(--heading-color);
  font-size: 21px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
}

/* portfolio, testimonials, and contact me */

.sub-title {
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

/* Navbar style start */
.navbar {
  z-index: 1000; /* makes sure that navbar always shows */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 85.333px;
  background: var(--white);
  box-shadow: 0px 5.333px 80px 0 rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.navbar--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}

.navbar--items ul > li > a {
  text-decoration: none;
}

/* navbar content */

.navbar--content {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

.navbar--active-content {
  color: var(--primary);
}
/* navbar style ends */

/* hero section style */

/* Existing or updated styles for the section--title class */
.section--title {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 10px;
}

.hero--section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 133.333px 85.333px 133.333px;
  aling-items: center;
  justify-content: space-between;
  gap: 32px;
  background-color: var(--bg-shade);
}

.hero--section--content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.hero--section--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.333px;
}

.hero-section--content--box > button {
  margin-top: 21.333px;
}

.hero--section--title {
  color: var(--heading-color);
  font-size: 74.667px;
  font-weight: 700;
  line-height: 90px;
  align-self: stretch;
}

.hero--section--title--color {
  color: var(--primary);
}

.hero--section-description {
  color: var(--darkblue);
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
}

.hero--section--img {
  display: flex;
}

.hero--section--img > img {
  width: 100%;
  height: 100%;
}

/* Hero section style ends */

/* Skills section style */

.skills--section {
  padding: 50px;
  background-color: #f9f9f9;
}

.section--title {
  /* already defined above; just reaffirming here if needed */
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 10px;
}

.skills--section--heading {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  text-align: center;
}

/* 
  Old card-based container & card styles are removed/commented out.
  We'll replace with "shapes" for each skill.
  
  .skills--section--container {
    ...
  }
  .skills--section--card {
    ...
  }
  etc.
*/

/* New "shape" container & shape items */

.skills--shape--container {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-items: center;
  align-items: center; 
  gap: 1.5rem;
}

.skill-shape {
  margin-bottom: 35px;
  width: 80px;
  height: 80px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: polygon(
    50% 0%,
    90% 25%,
    100% 60%,
    75% 100%,
    25% 100%,
    0% 60%,
    10% 25%
  );
  background: linear-gradient(
    135deg,
    #444, 
    #666
  );
  animation: float 3s ease-in-out infinite;
}

.skill-shape::before {
  content: "";
  position: absolute;
  inset: 0;
  /* subtle shading to mimic “stone” or “gem” look */
  background: radial-gradient(
    circle at 40% 40%, 
    rgba(255,255,255,0.1), 
    rgba(0,0,0,0.3)
  );
  mix-blend-mode: multiply;
}

.skill-shape img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* About Me */

.about--section {
  display: grid;
  padding: 133.3px 85.3px;
  align-items: center;
  gap: 114.6px;
  grid-template-columns: repeat(2, 1fr);
}

.about--section--img > img {
  width: 100%;
  height: 100%; 
}

/* About us ends */

/* My Portfolio starts here */

.portfolio--section {
  display: flex;
  padding: 112px 85px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
}

.portfolio--container-box {
  align-items: center;
  width: 100%;
}

.portfolio--container {
  text-align: center;
  margin-bottom: 20px;
}

.portfolio--section--container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 20px;
  padding: 0 20px;
}

.portfolio--section--card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  background: #fff;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.portfolio--section--card--content {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 32px;
}

.portfolio-section--title {
  color: var(--heading-color);
}

.portfolio--link {
  text-decoration: none;
  gap: 16px;
  border-bottom: 1.33px solid var(--primary);
  padding-bottom: 10.6px;
  display: flex;
  font-weight: 600;
}

.portfolio--section--card:hover path {
  stroke: #006b6a;
}
/* Portfolio ends */

/* Testimonials */
.testimonial--section {
  display: flex;
  padding: 112px 84.3px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  background-color: var(--bg-shade);
}

.sections--heading {
  color: var(--heading-color);
}

.testimonial--section--image {
  width: 150px; /* Set a fixed width */
  height: 150px; /* Set a fixed height */
  object-fit: contain; /* Maintain aspect ratio */
  border-radius: 8px; /* Optional: rounded corners */
  margin-bottom: 10px;
}

.testimonial--section--card {
  display: flex;
  padding: 42.6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 42.6px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1.33px solid #006b6a;
}

.testimonial--section--header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.testimonial--section--card--reviews {
  display: flex;
  align-items: flex-start;
  gap: 5.33px;
}

.testimonial--section--card--author--detail {
  display: flex;
  align-items: center;
  gap: 21.3px;
}

.testimonial--author--name {
  font-weight: 600;
  color: var(--heading-color);
}

.testimonial--author--designation {
  color: var(--darkblue);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

/* Testimonials end */

/* Contact Me */
.contact--section {
  display: flex;
  padding: 150px 85px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 64px;
}

.contact--form--container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(5, auto);
  width: 40%;
  row-gap: 32px;
}

.container{
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 32px;
  row-gap: 32px;
}

.contact--label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10.6px;
  flex: 1 0 0;
  width: 100%;
}

.contact--input {
  display: flex;
  font-family: "Robot";
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1.33px solid var(--primary);
  background: var(--white);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>') 
    white no-repeat 98.5% !important; /* !important used for overriding all other customisations */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>') 
    white no-repeat calc(100% - 20px); /* Better placement regardless of input width */
}
/*For IE*/
select::-ms-expand {
  display: none;
}

.checkbox--label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10.6px;
  flex: 1 0 0;
  justify-content: flex-start;
}

input[type="chehckbox"] {
  width: 21px;
  height: 21px;
  border: 0.15em solid var(--heading-color);
  border-radius: 0.15em;
  transform: translatey(-0.075em);
}

/* Contact End */

/* Footer start */
.footer--container {
  display: flex;
  padding: 106.667px 85.333px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--bg-shade);
  align-items: center;
  align-self: stretch;
}
.footer--link--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}
.footer--items ul > li > a {
  text-decoration: none;
}
.footer--social--icon > ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
}
.divider {
  margin: 106px 0 42.67px;
  height: 1.333px;
  width: 100%;
  background: rgba(40, 41, 56, 0.55);
}
.footer--content--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--content {
  color: var(--black);
  font-size: 18.667px;
  font-weight: 400;
  line-height: 28px;
}

/* Responsive Screens */
@media only screen and (max-width: 1800px) {
  .hero--section--title {
    font-size: 68px;
    line-height: 70px;
  }
  .skills--section--title {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 1600px) {
  .skills--section--heading {
    font-size: 54px;
    line-height: 70px;
  }
  .skills--shape--container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    justify-items: center;
    align-items: center; 
    gap: 16px;
  }
  .skill-shape {
    width: 70px;
    height: 70px;
  }

  .skill-shape img {
    width: 55%;
  }
}

@media only screen and (max-width: 1200px) {
  .btn-outline-primary {
    display: none;
  }
  .hero--section {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 70px;
  }
  .hero--section--title,
  .hero--section-description,
  .footer--content {
    text-align: center;
  }
  .about--section,
  .portfolio--section--container,
  .portfolio--container-box,
  .footer--link--container,
  .footer--items > ul,
  .footer--content--container {
    display: flex;
    flex-direction: column;
  }
  .skills--section,
  .portfolio--container,
  .hero--section--content,
  .hero--section--content--box {
    align-items: center;
  }
  .portfolio--container-box {
    text-align: center;
    margin-bottom: 20px;
  }

  .testimonial--section {
    padding: 20px;
    background-color: #f9f9f9;
  }
  .skills--section,
  .contact--section,
  .footer--container {
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .about--section {
    gap: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .testimonial--section--card {
    padding: 25px;
  }

  .testimonial--section--date {
    margin-top: 0;
    margin-bottom: 16px; 
    font-size: 0.9rem; 
    color: #555; 
  }

  .hero--section--title,
  .skills--section--heading,
  .sections--heading {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  .hero--section--content--box {
    gap: 10px;
  }
  .contact--form--container {
    width: 100%;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .footer--social--icon > ul,
  .footer--items > ul {
    padding: 0;
    align-items: center;
  }
  .divider {
    margin: 20px;
  }
}

/* Hamburger menu  */

.nav__hamburger {
  display: none;
  width: 1.875rem;
  height: 1.313rem;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 25px;
  right: 25px;
}

.nav__hamburger__line {
  display: block;
  height: 0.188rem;
  width: 100%;
  background-color: #000000;
  border-radius: 0.625rem;
  transition: all ease-in-out 0.2s;
}

@media screen and (max-width: 1200px) {
  .nav__hamburger {
    display: flex;
    z-index: 200;
  }
  .navbar--items {
    display: flex;
  }
  .navbar--items {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255);
    top: -20rem;
    left: 0;
    width: 100%;
    transition: all ease-in-out 0.4s;
  }
  .navbar--items ul {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem 0 0.6rem;
    z-index: -100;
  }
  .navbar--items ul li {
    text-align: center;
  }
  .navbar--items ul li a {
    padding: 0.5rem;
  }
  .navbar--items {
    z-index: -1000;
  }
  .navbar--items.active {
    top: 30px;
  }
  .nav__hamburger.active :nth-child(1) {
    transform: rotate(45deg) translate(0.45rem, 0.1875rem);
  }

  .nav__hamburger.active :nth-child(2) {
    opacity: 0;
  }

  .nav__hamburger.active :nth-child(3) {
    transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
  }
}

@media only screen and (max-width: 1024px) {
  .skills--shape--container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .skills--shape--container {
    grid-template-columns: repeat(2, 1fr);
  }
  .skill-shape {
    width: 60px;
    height: 60px;
  }
  .skill-shape img {
    width: 45px;
    height: 45px;
  }
}